body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
    margin:0;
}
html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}
a {
    color: #006DBA;
    cursor: pointer;
    text-decoration: underline;
}
.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: 40px;
}
.dx-toolbar .dx-toolbar-items-container {
    height: 40px;
}
.dx-pager {
    padding: 5px 14px;
    height: 40px;
}
.dx-scrollable-scroll {
    height: 13px !important;
}
.dx-scrollbar-hoverable {
    height: 12px !important;
}
.dx-scrollbar-vertical .dx-scrollable-scroll {
    height: 12px !important;
}
#dx-datagrid-header-panel .dx-icon  {
    color: #03A9F4;
}
.dx-datagrid-header-panel {
    padding: 0 14px;
}
.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content .dx-icon {
    color: #03A9F4;
}
.dx-datagrid .dx-row > td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    height: 35px !important;
}
.dx-datagrid-filter-panel {
    height: 35px;
    padding: 12px 14px;
}
.dx-header-filter-menu .dx-list-item {
    width: auto;
}
.dx-list-item-content {
    padding: 8px;
}
.dx-datagrid-borders > .dx-datagrid-filter-panel {
    border-top-width: 0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-bottom: none;
}
.dx-datagrid .dx-datagrid-table .dx-datagrid-filter-row > td {
    padding: 0 0 0 7px !important;
}
.dx-datagrid .dx-datagrid-table .dx-datagrid-filter-row .dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 7px 0 0 7px !important;
}
.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 0 !important;
}
.dx-datagrid .dx-editor-with-menu {
    height: 35px;
}
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
    padding-left: 7px;
    height: 35px;
}
.dx-datagrid .dx-editor-with-menu .dx-texteditor .dx-texteditor-input, .dx-datagrid-container .dx-editor-with-menu .dx-texteditor .dx-texteditor-input {
    padding-left: 30px;
}
.dx-datagrid-headers.dx-header-multi-row .dx-row.dx-header-row > td {
    vertical-align: middle;
}
.dx-datagrid .dx-datagrid-table .dx-datagrid-filter-row>td {
    padding: 0 !important;
}
.dx-button-has-icon.dx-button-has-text .dx-button-content {
    padding: 6px !important;
}
.dx-datagrid .dx-datagrid-table .dx-datagrid-filter-row .dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 4px 0 0 0 !important;
}
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending), .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all), .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td:not(.dx-validation-pending):not(.dx-datagrid-select-all) {
    padding-right: 8px;
    padding-left: 8px;
    vertical-align: middle;
}

.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
    padding: 4px;
}
/* Tree List */
#dx-treelist-header-panel .dx-icon  {
    color: #03A9F4;
}
.dx-treelist.dx-menu .dx-menu-item .dx-menu-item-content .dx-icon {
    color: #03A9F4;
}
.dx-treelist .dx-row > td {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dx-treelist-headers .dx-treelist-table .dx-row > td {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    height: 40px !important;
}
.dx-treelist-filter-panel {
    height: 40px;
    padding: 12px 14px;
}
.dx-treelist-borders > .dx-treelist-filter-panel {
    border-top-width: 0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-bottom: none;
}
.dx-treelist .dx-treelist-table .dx-treelist-filter-row > td {
    padding: 0 0 0 7px !important;
}
.dx-treelist .dx-treelist-table .dx-treelist-filter-row .dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 7px 0 0 7px !important;
}
.dx-treelist .dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 0 !important;
}
.dx-treelist .dx-editor-with-menu {
    height: 35px;
}
.dx-treelist-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
    padding-left: 18px;
    height: 35px;
}
.dx-treelist-headers.dx-header-multi-row .dx-row.dx-header-row > td {
    vertical-align: middle;
}
/* Tree List End */
.dx-header-filter-menu .dx-list-item {
    width: auto;
}
/*Popup css */
.dx-popup-bottom.dx-toolbar {
    border-top: 1px solid #e0e0e0;
}
.dx-popup-content {
    padding: 10px;
}
td.dx-editor-cell {
    padding: 0 !important;
}
.dx-popup-title.dx-toolbar {
    background: #4378b8;
    color: #fff;
    padding: 0 7px 0 14px;
}
.dx-popup-title.dx-toolbar .dx-toolbar-label {
    font-size: 14px;
}
.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: 35px;
}
.dx-popup-title.dx-toolbar .dx-button.dx-closebutton {
    display: block;
    background-color: transparent !important;
}
/*@media screen and (min-width: 3048px) {*/
@media only screen and (min-width: 2024px) {
    .dx-datagrid .dx-row > td {
        height: 50px !important;
        font-size: 35px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
        height: 50px !important;
        font-size: 35px;
        line-height: 25px;
    }
    .dx-treelist .dx-row > td {
        height: 50px !important;
        font-size: 35px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .dx-treelist-headers .dx-treelist-table .dx-row > td {
        height: 50px !important;
        font-size: 35px;
        line-height: 25px;
    }
}
/** Non Dx css **/
.dxx-panel {
    background-color: #fff;
    border: none;
    border-radius: 4px;
    margin-bottom: 0;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, .2);
    -moz-box-shadow: 1px 2px 6px rgba(0, 0, 0, .2);
    -webkit-box-shadow: 1px 2px 6px rgba(0, 0, 0, .2);
}
.dxx-panel-heading {
    color: #fff;
    background-color: #4378B8;
    border-color: #4378B8;
    height: 35px;
    padding: 7px 14px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.dxx-panel-body {
    padding: 0;
}
@media (min-width: 950px) {
    .dxx-container {
        padding: 15px;
        box-sizing: border-box;
    }
}
.alert-critical {
    background-color: #f44336 !important;
    color: #fff;
}
.alert-major {
    background-color: #ff9800 !important;
    color: #fff;
}
.alert-minor {
    background-color: #ffeb3b !important;
    color: #fff;
}
.alert-warning {
    background-color: #03a9f4 !important;
    color: #fff;;
}
.alert-unknown {
    background-color: #03a9f4 !important;
    color: #fff;;
}
.alert-normal {
    background-color: #008000 !important;
    color: #fff;
}
.label {
    display: inline;
    padding: .2em .3em;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    font-size: 100%;
}

@media (min-width: 600px) {
    .MuiToolbar-gutters {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}
.fullscreen-enabled {
    background-color: #ffffff;
}
.network-event-popup {
    padding: 0 !important;
}
/*####Recharts Styles ##########*/
.recharts-default-tooltip {
    background-color: #616161 !important;
    border: none !important;
    border-radius: 8px;
    z-index: 1400;
}

/*#####swagger-ui styles #######*/
.swagger-ui {
    margin-top: -40px;
}

.swagger-ui .scheme-container {
    margin-top: -40px;
    padding: 10px 0;
}
.swagger-ui .opblock-tag {
    padding: 4px !important;
}
.swagger-ui .authorization__btn {
    display: none;
}
.swagger-ui .auth-wrapper .authorize {
    display: none;
}

/* Remove google logo */

.gm-style-cc {
    display: none !important;
}
.gm-style a[href^="https://maps.google.com/maps"] {
    display: none !important;
}
#device-search-pie-chart {
    display: flex;
    justify-content: center;
}

/** Okta login page **/
#okta-sign-in .auth-header {
    background-image: url("./images/brand/NMS_Tools_2_200x45.png");
    background-color: #4378b8;
    color: #ffffff;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center;
}
#okta-sign-in.auth-container .button-primary {
    background-color: #4378b8;
    color: #ffffff;
}
#okta-sign-in .beacon-container.beacon-small {
    height: 20px;
    width: 20px;
    bottom: -20px;
}
/** End Okta login page **/
.label-status {
    padding: 2px;
    line-height: .60em;
}

/* DevExtreme fields set and select */
.dx-rtl.dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input, .dx-rtl .dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input, .dx-rtl.dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input, .dx-rtl .dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input, .dx-texteditor.dx-editor-filled .dx-placeholder::before, .dx-texteditor.dx-editor-filled .dx-texteditor-input, .dx-texteditor.dx-editor-outlined .dx-placeholder::before, .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
    padding: 4px 12px;
    font-size: 13px;
}
.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
    padding: 8px 12px;
}
.dx-list-item-content {
    font-size: 13px
}
/*.dx-button-has-icon .dx-icon {
    margin-left: -6px;
}*/

/* DevExtreme fields set and select */

i.mdi-color {
    color: darkgreen;
}
/* dxList css used for dbtable list */
.dx-icon-search {
    color: #03A9F4 !important;
}
.dx-search-box.dx-icon-search {
    color: #03A9F4 !important;
}
.dx-texteditor.dx-editor-underlined::after {
    border-bottom: 1px solid #e0e0e0;
}
.dx-texteditor.dx-editor-underlined.dx-state-over::after {
    border-bottom: 2px solid #03A9F4;
}
/*.dx-list {
    border: 1px solid #e0e0e0 !important
}
.dx-list-item-content {
    padding: 2px;
    border: 1px solid #e0e0e0 !important
}
.dx-list-item-content:after {
    margin-top: 0;
    height: 0;
}
.dx-list-item {
    border: none !important;
    margin: 0;
    cursor: auto;
}
.dx-list-item:first-of-type {
    margin-top: 0 !important;
}
.dx-list-item:nth-of-type(odd) {
    background-color: #e0e0e0 !important;
}
.dx-list-item .dx-state-hover {
    background-color: #e1f5ff !important;
}
*/
#db-details-list > .dx-list-item-content {
    padding: 2px;
    border: 1px solid #e0e0e0 !important
}
.db-details-list-cls > .dx-list-item-content {
    padding: 2px;
    border: 1px solid #e0e0e0 !important
}
.db-details-list-cls {
    border: 1px solid #e0e0e0 !important
}
.db-details-search-cls {
    padding-top: 0;
}
