.slice {
  cursor: pointer;
}

.slice .main-arc {
  stroke: #fff;
}

.slice .hidden-arc {
  fill: none;
}
.total-percent {
  pointer-events: none;
}
.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
rect {
  stroke-width: 2px;
}
.slice text {
  pointer-events: none;
  text-anchor: middle;
  dominant-baseline: middle;
}